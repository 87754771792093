import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Payment from './components/Payment';
import Home from './Home';

function App() {
  return (
    <Router>
      <div className="container-div">
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route path="/payment" component={Payment}/>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
