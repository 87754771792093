import { Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';


const Footer = () => {
    return (
        <>
            <Grid sx={
                {
                    background: '#141414',
                    color: '#ffffff',
                    textAlign: 'center',
                    paddingY: 5,
                }
            }>
                <div>
                    <Typography variant="h6" fontWeight="bold">Follow up for any updates or inquiries</Typography>

                    <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/fontsnatcher"> <FontAwesomeIcon icon={faTwitter} style={{ marginTop: "10px", marginBottom: "10px", fontSize: "30px", color: "#00acee", }}/> </a><br/>

                    <Typography variant="p" color="gray">© 2021 Fontsnatcher by <a style={{ color: "gray", }} rel="noopener noreferrer" target="_blank" href="https://twitter.com/itsjojoduke1">Jojo Duke</a></Typography>
                </div>
            </Grid>
        </>
    )
}

export default Footer;
