import { Typography, Container } from '@mui/material';
import Faq from 'react-faq-component';

function Faqs() {
    const data = {
        rows: [
          {
            title: "What makes Fontsnatcher better than other alternatives?",
            content: "What Fontsnatcher does is simply combine the aspects of its alternatives whiles giving a different and unique experience. You can identify fonts, try them out and discover new fonts all for a cheap one-time deal. Its the best of all worlds."
          },
          {
            title: "What websites can this work on?",
            content: "Fontsnatcher works on every and any website on the internet, provided that website has text on it, Fontsnatcher can identify it."
          },
          {
            title: "Can I try out the font?",
            content: "Yes. Once you've successfully identified which fonts are active on the site you can then try them out directly in-app without having to open external design progams."
          },
        ]
      }

    return (
        <div>
            <Container maxWidth="xl" sx={{ marginTop: 20, backgroundColor: "#ffffff", paddingY: 10, width: "100%", }}>
            <div id="hiw" className="howitworks"><Typography variant="h2" sx={{ textAlign: "center", marginBottom: 10, }}>FAQ</Typography></div>
                <div style={{ width: "80%", margin: "auto", }}>
                    <Faq data={data} styles={{ rowContentColor: "#8c8c8c", }}/>
                </div>
            </Container>
        </div>
    )
}

export default Faqs
