import './Payment.css';
import logo from '../images/fontsnatcher-logo.png'

function Payment() {
    return (
        <div>
            <img 
                className="loglo" 
                    src={logo} 
                    alt="fontsnatcher-logo"
                    width="180"
                    />

            <div id="pricing">
                <div class="price_card">
                    <div class="header">
                        <span class="name">Monthly</span>
                        <span class="price">$3/month</span>
                        <span class='billing'>Billed every month</span>
                    </div>
                    <ul class="features">
                        <li>Discover the fonts of the web</li>
                        <li>Tryout fonts in the in-app editor</li>
                        <li>Chrome, Firefox</li>
                        <li>Use on unlimited websites</li>
                    </ul>
                    <button className='get-btn'>Get Fontsnatcher Plus</button>
                </div>
            </div>
        </div>
    )
}

export default Payment;
