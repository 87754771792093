import { Typography } from '@mui/material';
import './Coming.css';
import bigfont from '../images/bigfont.png';


const Coming = () => {
    return (
        <>
            <div className="coming-grid">
                <div className="fna"><Typography variant="h3">Learn a new font, try it out, get your creative juices flowing and become a better designer on the way!</Typography></div>
                <div className="fnb">
                    <img width="230" src={bigfont} alt="bigfont"/>
                    <button 
                        className="waitlist-btn"
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href='https://chrome.google.com/webstore/detail/fontsnatcher/bkbjgdkgamoljeaabnjpelaigcdnmdpi?hl=en-GB&authuser=0';
                        }}>
                        Try it out now
                    </button>
                </div>
            </div>
        </>
    )
}

export default Coming;
