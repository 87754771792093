import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
/* eslint-disable no-loop-func */
import { Mixpanel } from './mixpanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import Navbar from './components/Navbar';
import Main from './components/Main';
import HowItWorks from './components/HowItWorks';
import Coming from './components/Coming';
import Footer from './components/Footer';
import Faqs from './components/Faqs'

function Home() {
  Mixpanel.track('Got a new visitor');

  return (
    <div className="container-div">
      <button className='donate-btn' onClick={(e) => { 
        e.preventDefault();
        window.location.href="https://chrome.google.com/webstore/detail/fontsnatcher/bkbjgdkgamoljeaabnjpelaigcdnmdpi?hl=en-GB&authuser=0";
       }}> <FontAwesomeIcon icon={faHeart} style={{ marginRight: 10, }}/> Donate</button>

       
       <button className='donate-btn-round' onClick={(e) => {
                                e.preventDefault();
                                Mixpanel.track('Clicked Donate Button');
                                window.location.href='https://flutterwave.com/pay/fonsnatcher-lifetime';
                            }}><FontAwesomeIcon icon={faHeart}/></button>

<a href='https://www.sideprojectors.com/project/25842/fontsnatcher-discover-the-fonts-of-the-web' alt='Fontsnatcher - Discover the fonts of the web is for sale at @SideProjectors'><img style={{position:"fixed",top:"-5px", right: "20px", border: 0, zIndex:10000,}} src='https://www.sideprojectors.com/img/badges/badge_2_red.png' alt='Fontsnatcher - Discover the fonts of the web is sale at @SideProjectors'/></a>
      <Navbar/>
      <Main/>
      <HowItWorks/>
      <Coming/>
      <Faqs/>
      <Footer/>
    </div>
  );
}

export default Home;
