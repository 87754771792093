import { AppBar, Toolbar } from '@mui/material';
//import { Link } from 'react-router-dom';
import logo from '../images/fontsnatcher-logo.png';
import './NavBar.css';
import { Mixpanel } from '../mixpanel';


const Navbar = () => {
    return (
        <>
            <AppBar position="sticky" sx={{ backgroundColor: "#ffffff", }}>
                <Toolbar className="navy" sx={{ display: "flex", justifyContent: "space-between", position: "sticky", }}>
                {/* Fontsnatcher Logo and Wordmark */}
                <img 
                    className="logo" 
                    src={logo} 
                    alt="fontsnatcher-logo"
                    width="180"
                    />

                {/* Join Waitlist Button */}
                <div>
                    <button 
                        className="firstWaitlist-btn"
                        onClick={(e) => {
                                e.preventDefault();
                                window.location.href='https://chrome.google.com/webstore/detail/fontsnatcher/bkbjgdkgamoljeaabnjpelaigcdnmdpi?hl=en-GB&authuser=0';
                            }}>
                        Add to Chrome
                    </button>
                    <button 
                        className="tryExBtn"
                        onClick={(e) => {
                                e.preventDefault();
                                window.location.href='https://flutterwave.com/pay/fonsnatcher-lifetime';
                                Mixpanel.track('Clicked Donate Button');
                            }}>
                        Donate - Toss a coin...
                    </button>
                </div>
                
                </Toolbar>
            </AppBar>
        </>
    )
}

export default Navbar;
