import { Typography } from '@mui/material';
//import { Link } from 'react-scroll';
import fonts from '../images/fonts.png';
import fontPreview from '../images/preview.mp4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import './Main.css';


const Main = () => {
    return (
        <div className="the-div">
            <div className="clutch">
                <Typography className="header1" variant="h1">Discover the<img className="img-fonts" src={fonts} alt="fonts" width="320"/> being<br/> used on the web</Typography>
                <Typography className="header2" variant="h4">Fontsnatcher helps you in identifying, testing and discovering 
                <br/> new fonts and typefaces being used on the internet</Typography>
            </div>
            <button className="hiw-btn" 
                            onClick={(e) => {
                            e.preventDefault();
                            window.location.href='https://chrome.google.com/webstore/detail/fontsnatcher/bkbjgdkgamoljeaabnjpelaigcdnmdpi?hl=en-GB&authuser=0';
                        }}> 
                <FontAwesomeIcon icon={faShoppingCart} style={{ color: "#ffffff", marginRight: "15px", }}/>
                Add to Chrome
            </button>

            <button className='hiw-btn2'
                    onClick={(e) => {
                        e.preventDefault();
                        window.location.href='https://addons.mozilla.org/en-GB/firefox/addon/fontsnatcher/';
                    }}>
                Add to Firefox
            </button>

            <div className="demo">
                <video src={fontPreview} muted loop autoplay="true" controls="true" height="540" width="960"></video>
            </div>
        </div>
    )
}

export default Main;
