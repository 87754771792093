import mixpanel from 'mixpanel-browser';
mixpanel.init('adc15e3096460b20a909dcbc014ae0ee', {debug: true});

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  track_links: (query, event_name) => {
    mixpanel.track_links(query, event_name);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;